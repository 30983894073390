import React, { useState } from 'react';
import { RiArrowDropRightLine } from 'react-icons/ri';
import video  from '../MainHeader/Pentacam_Cornea_OCT_Video.mp4'
import './MainHeader.css';

const MainHeader = () => {
  const Oculus =
    'https://lh3.googleusercontent.com/d/1F5Ir28A5JQV0O8aM5Rt17dZhIocCcLJh';

  const Pentacam_Product =
    'https://lh3.googleusercontent.com/d/155dA9_vUWr-oqq61hNui7fBTtXS7cdJk';
 
    const [isModalOpen, setIsModalOpen] = useState(false);
  

  return (
    <header>
      <div className="container">
        <div className="header__container">
          <div className="header__left">
            <div className="row_header_left">
              <div className="imagen_pentacam">
                <img src={Pentacam_Product} alt="Logo Oculus" loading="lazy" />
              </div>
              <div className="texto_pentacam">
                <div className="oculus_logo">
                  <img src={Oculus} alt="Logo Oculus" loading="lazy" />
                </div>
                <div className="imagen_pentacam_mobil">
                <img src={Pentacam_Product} alt="Logo Oculus" loading="lazy" />
              </div>
                <h1> Pentacam® Cornea OCT</h1>
                <p className="promo-text">
                  Combina tecnología Scheimpflug con el primer sistema de
                  escaneo OCT pericéntrico del mundo.
                </p>
                <p className="promo-availability">
                  Disponible en LATAM <strong>próximamente</strong>
                </p>
                <button className="mainheader__btn" onClick={() => setIsModalOpen(true)}>
                  
                    Ver más
                    <span className="arrow_header">
                      <RiArrowDropRightLine />
                    </span>
                  
                </button>
              </div>
            </div>
          </div>

          <div className="header__right"></div>
        </div>
      </div>

      {/* Video en modal */}
      {isModalOpen && (
        <div className="video-modal" onClick={() => setIsModalOpen(false)}>
          <div className="video-container_header" onClick={(e) => e.stopPropagation()}>
            <button className="close-btn" onClick={() => setIsModalOpen(false)}>X</button>
            <video controls autoPlay>
              <source src={video} type="video/mp4" />
              Tu navegador no soporta la reproducción de video.
            </video>
          </div>
        </div>
      )}
    </header>
  );
};

export default MainHeader;
